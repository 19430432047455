import(/* webpackMode: "eager", webpackExports: ["SimpleSwapBridgeBanner"] */ "/vercel/path0/apps/web/src/ui/swap/simple/simple-swap-bridge-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleSwapHeader"] */ "/vercel/path0/apps/web/src/ui/swap/simple/simple-swap-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleSwapSettingsOverlay"] */ "/vercel/path0/apps/web/src/ui/swap/simple/simple-swap-settings-overlay.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleSwapSwitchTokensButton"] */ "/vercel/path0/apps/web/src/ui/swap/simple/simple-swap-switch-tokens-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleSwapTokenNotFoundDialog"] */ "/vercel/path0/apps/web/src/ui/swap/simple/simple-swap-token-not-found-dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleSwapToken0Input"] */ "/vercel/path0/apps/web/src/ui/swap/simple/simple-swap-token0-input.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleSwapToken1Input"] */ "/vercel/path0/apps/web/src/ui/swap/simple/simple-swap-token1-input.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleSwapTradeButton"] */ "/vercel/path0/apps/web/src/ui/swap/simple/simple-swap-trade-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleSwapTradeStats"] */ "/vercel/path0/apps/web/src/ui/swap/simple/simple-swap-trade-stats.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SwapMaintenanceMessage"] */ "/vercel/path0/apps/web/src/ui/swap/simple/swap-maintenance-message.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StrapiBanner"] */ "/vercel/path0/apps/web/src/ui/swap/strapi-banner/strapi-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SwapModeButtons"] */ "/vercel/path0/apps/web/src/ui/swap/swap-mode-buttons.tsx");
